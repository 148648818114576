.notification {
  max-width: 23rem;
  overflow: hidden;
}

.notification--success {
  background-color: var(--color-green-05);
  border: solid 1px var(--color-green-60);
}

.notification--success .notification__icon .icon {
  color: var(--color-green-80);
}

.notification--error {
  align-items: flex-start;
  background-color: var(--color-red-05);
  border: solid 1px var(--color-red-60);
}

.notification--error .notification__icon .icon {
  color: var(--color-red-100);
}

.notification__message-container {
  display: flex;
  flex-direction: column;
}

.notification > :not(:last-child) {
  margin-right: 1.25rem;
}

.notification__cancel {
  cursor: pointer;
}
