.side-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: var(--color-dark-100);
  background: rgba(12, 15, 28, 0.2);
}

.side-menu__column {
  width: 14.125rem;
  height: 100%;
  background: var(--color-light-100);
}

.side-menu__header {
  display: flex;
  flex-direction: flex-start;
  gap: calc(var(--gap-default) / 2);
  align-items: center;
  width: 14.125rem;
  height: 3.5rem;
  margin-bottom: 1.5rem;
  margin-left: var(--dimension-unit);
}

.side-menu__header > .appheader__svg {
  height: 3rem;
}
.side-menu__header .icon {
  padding: var(--dimension-unit);
  stroke-width: 2;
}
.side-menu__header .icon:hover {
  background-color: var(--color-dark-10);
  border-radius: 50px;
  cursor: pointer;
}
.side-menu__item {
  display: flex;
  align-items: center;
  padding: 1rem;
  color: var(--color-dark-80);
}

.side-menu__item:hover {
  background-color: var(--color-dark-05);
  cursor: pointer;
}

.side-menu__item--active {
  color: var(--color-dark-100);
  background-color: var(--color-dark-10);
}

.side-menu__icon,
.side-menu__picking-icon,
.side-menu__unsold-icon,
.side-menu__returns-icon {
  margin-right: 1.25rem;
}

.side-menu__icon path {
  fill: var(--color-dark-100);
  stroke: none;
}

.side-menu__item--active .side-menu__icon path {
  fill: var(--color-dark-100);
  stroke: none;
}

.side-menu__routes-icon {
  stroke-width: 2;
  margin-right: 1.25rem;
}

.side-menu__verification-icon {
  margin-right: 1.25rem;
  stroke-width: 2;
}

.side-menu__picking-icon path {
  stroke: var(--color-dark-80);
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke-linecap: square;
}

.side-menu__item--active .side-menu__picking-icon path {
  stroke: var(--color-dark-100);
}

.side-menu__returns-icon path {
  stroke-width: 2;
}

.side-menu__unsold-icon path {
  stroke: var(--color-dark-80);
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke-linecap: square;
}

.side-menu__item--active .side-menu__unsold-icon path {
  stroke: var(--color-dark-100);
}
