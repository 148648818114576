.error-reporter {
  width: 80%;
  max-width: 560px;
  margin: calc(var(--dimension-unit) * 10) auto;
  padding: var(--dimension-4-unit);
  background-color: white;
  box-shadow: var(--box-shadow-default);
}

.error-reporter__title {
  margin-top: 0;
  margin-bottom: var(--dimension-4-unit);
  color: var(--color-text-danger);
}

.error-reporter__description {
  margin-bottom: var(--dimension-4-unit);
}
