.appheader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 3.5rem;
  color: var(--color-light-100);
  background-color: var(--color-dark-100);
}

.appheader__logo {
  display: flex;
  gap: var(--gap-default);
  align-items: flex-start;
  width: 14rem;
  padding-left: 0.5rem;
}
.appheader__logo .icon {
  padding: var(--dimension-unit);
  stroke-width: 2;
}
.appheader__logo .icon:hover {
  background-color: var(--color-dark-60);
  border-radius: 50px;
  cursor: pointer;
}

.appheader__logo > .appheader__svg {
  height: 3rem;
}

.appheader__employee {
  display: flex;
  gap: calc(var(--gap-default) / 2);
  align-items: center;
  justify-content: flex-end;
  margin-right: 1rem;
  margin-left: 9rem;
  cursor: pointer;
}

.appheader__avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  text-align: center;
  background-color: var(--color-red-60);
  border-radius: 50%;
}

.appheader__employee-menu {
  position: absolute;
  top: 3rem;
  right: 1.55rem;
  display: none;
  display: flex;
  flex-direction: column;
  color: var(--color-dark-80);
  background-color: var(--color-light-100);
  border-radius: 3px;
  box-shadow:
    0px 0px 2px rgba(0, 0, 0, 0.2),
    0px 2px 10px rgba(33, 43, 54, 0.1);
}

.appheader__employee-menu-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.875rem 1rem;
  border-bottom: 0.06rem solid var(--color-dark-10);
  cursor: pointer;
}

.appheader__employee-menu-item--header {
  padding: 1rem;
  cursor: default;
}

.appheader__logout-icon {
  margin-right: 1rem;
}

.appheader__logout-icon path {
  stroke-width: 2;
}
